<template>
  <v-container fluid>
    <v-card raised>
      <v-progress-linear
        indeterminate
        color="trukkin-theme darken-2"
        v-show="loading"
      ></v-progress-linear>
      <v-card-title primary-title class="bg-clr">
        <v-btn fab flat icon class="nospace" @click.native="close">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <span class="headline">&nbsp;User Roles and Permissions</span>
      </v-card-title>
    </v-card>
    <v-card-text>
      <v-container>
        <v-layout row>
          <v-flex xs4 pr-4>
            <div class="subheading muted">User Name</div>
            <div class="heading">{{ userData.name }}</div>
          </v-flex>
          <v-flex xs4 pr-4>
            <div class="subheading muted">Email</div>
            <div class="heading">{{ userData.email }}</div>
          </v-flex>
          <v-flex xs4 pr-4>
            <div class="subheading muted">User Id</div>
            <div class="heading">{{ userData.userId }}</div>
          </v-flex>
        </v-layout>
      </v-container>
      <v-container>
        <v-layout row>
          <v-flex xs4 pr-4>
            <div class="subheading muted">Phone No</div>
            <div class="heading">{{ userData.phoneNo || "N.A" }}</div>
          </v-flex>
          <v-flex xs4 pr-4>
            <div class="subheading muted">Country</div>
            <div class="heading">{{ userData.country || "N.A" }}</div>
          </v-flex>
          <v-flex xs4 pr-4>
            <div class="subheading muted">User Type</div>
            <div class="heading" v-if="userDetails.userType.userTypeTitle">
              {{ userDetails.userType.userTypeTitle || "N.A" }}

              <v-tooltip
                top
                v-if="userData.userId != userId"
                class="editCustomer"
                content-class="tooltip-op"
                color="blue darken-1"
              >
                <template slot="activator">
                  <v-icon
                    color="blue darken-1"
                    flat
                    prepend-icon
                    @click.native="editRole()"
                    class="customer-actions"
                    >create
                  </v-icon>
                </template>
                <span class="tooltip">Edit Role</span>
              </v-tooltip>
            </div>

            <v-select
              v-else
              v-model="selectedUserType"
              :items="userTypeItems"
              item-text="userTypeTitle"
              item-value="_id"
              label="Select User Type"
              persistent-hint
              return-object
              single-line
              @change="updateRole('')"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-container>
      <v-container>
        <v-layout row wrap align-center>
          <v-flex
            md6
            class="communication"
            v-if="userDetails.userType.userTypeTitle"
          >
            <div class="subheading muted">Roles of User Type</div>

            <v-checkbox
              :key="i"
              value
              disabled
              checked
              v-for="(data, i) in this.roles"
              v-model="selected"
              :label="` ${data.role.roleTitle.toString()}`"
            ></v-checkbox>
          </v-flex>
          <v-flex
            md6
            class="communication"
            v-if="extraRoles && userData.userId != userId"
          >
            <div class="subheading muted">Extra Roles</div>
            <v-checkbox
              :key="i"
              v-for="(data, i) in this.rolesItems"
              v-model="data.isChecked"
              v-on:change="updateRole(data)"
              :label="` ${data.roleTitle.toString()}`"
            ></v-checkbox>
          </v-flex>
        </v-layout>
      </v-container>
      <span
        >*** Note: Removing Low Level Role will not impact if high level user
        type is assigned.</span
      >
    </v-card-text>
    <ErrorBox :error="x.error" />
  </v-container>
</template>
<script>
import { StorageKeys } from "../../constants/constants";
import ErrorBox from "@/components/Common/ErrorBox";
import { user, getAllRoles } from "../../constants/api-urls";
export default {
  components: {
    ErrorBox,
  },
  created() {
    this.getUserPermission();
    this.getUserDetails();
    this.userId = localStorage.getItem("userId");
  },
  data() {
    return {
      loading: false,
      selected: true,
      selectedUserType: "",
      previousRole: null,
      userTypeItems: [],
      userDetails: {
        userType: {},
      },
      roles: [],
      extraRoles: false,
      rolesItems: [],
      assignedRole: [],
      userData: {},
      x: {
        error: "",
      },
    };
  },
  methods: {
    close() {
      this.$router.go(-1);
    },
    getAllUserType() {
      this.loading = true;
      let url = "/getWeightedUserType";
      delete this.axios.defaults.headers.common["token"];
      let body = {
        userId: localStorage.getItem("userId"),
      };
      this.axios.post(this.constants.rbacUrl + url, body).then(
        (response) => {
          this.userTypeItems = response.data.data;
          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to Fetch User Types";
        }
      );
    },
    getUserDetails() {
      this.loading = true;
      let url = user.getUserDetails;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let userId = this.$route.params.id;
      this.axios
        .get(this.constants.adminAPI + url + "?userId=" + userId, config)
        .then(
          (response) => {
            this.userData = response.data.data[0];

            this.loading = false;
          },
          (error) => {
            this.x.error = "Failed to Fetch Admin";
            this.loading = false;
          }
        );
    },
    editRole() {
      this.previousRole = this.userDetails.userType.userTypeTitle;
      this.userDetails.userType = {};
      this.getAllUserType();
    },
    updateLogs() {
      this.loading = true;
      let url = user.saveActivityForRoles;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        userId: this.$route.params.id,
        beforeUserRole: this.previousRole,
        afterUserRole: this.selectedUserType.userTypeTitle,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.userTypeItems = response.data.data;
          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to Fetch User Types";
        }
      );
    },
    updateRole(data) {
      if (data == "") {
        this.userDetails.userType._id = this.selectedUserType._id;
      }
      this.loading = true;
      let roles = this.roles.map((x) => x.role._id);
      let permissions = [];
      if (data.isChecked) {
        roles.push(data._id);
      } else {
        var index = roles.indexOf(data._id);
        if (index > -1) {
          roles.splice(index, 1);
        }
      }
      let body = {
        userId: parseInt(this.$route.params.id),
        userType: this.userDetails.userType._id,
        roles,
        permissions,
      };
      let url = user.assignPermissiontoUser;
      this.axios.post(this.constants.rbacUrl + url, body).then(
        (response) => {
          this.updateLogs();
          this.getUserPermission();
          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to Assign User Type To User";
          this.loading = false;
        }
      );
    },
    async logoutUser(id) {
      let url = `/logoutById?userId=${id}`;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const response = await this.axios.get(
        this.constants.apiUrl + url,
        config
      );
      if (response.status == 200) {
        return response.data.data;
      } else {
        this.loading = false;
      }
    },

    getUserPermission() {
      let userId = parseInt(this.$route.params.id);
      let body = {
        userId,
      };
      this.loading = true;
      let url = user.checkUserPermission;
      delete this.axios.defaults.headers.common["token"];
      this.axios.post(this.constants.rbacUrl + url, body).then(
        (response) => {
          if (response.data.data.userType) {
            this.userDetails = response.data.data;
            this.getRolesByUserType(response.data.data.userType._id);
          } else {
            this.userTypeItems = this.getAllUserType();
          }

          this.loading = false;
        },
        (error) => {
          this.userTypeItems = this.getAllUserType();
          this.x.error = "Failed to Fetch User Type";
          this.loading = false;
        }
      );
    },
    async getAllRoles() {
      this.loading = true;
      let url = getAllRoles;
      delete this.axios.defaults.headers.common["token"];
      const response = await this.axios.get(this.constants.rbacUrl + url);
      if (response.status == 200) {
        this.loading = false;
        return response;
      } else {
        this.x.error = "Failed to Fetch Roles.";
      }
    },
    async comparer(otherArray) {
      return function (current) {
        return (
          otherArray.filter(function (other) {
            return (
              other.value == current.value && other.display == current.display
            );
          }).length == 0
        );
      };
    },
    async getRolesByUserType(userType) {
      if (userType) {
        this.loading = true;
        let url = user.assignedRoletoUserType;
        delete this.axios.defaults.headers.common["token"];
        let roles = await this.axios.get(
          this.constants.rbacUrl + url + "/" + userType
        );
        let allRoles = [];

        this.roles = roles.data.data.roles;
        let res = await this.getAllRoles();
        allRoles = res.data.data;
        if (this.userDetails.roles) {
          let userRoles = this.userDetails.roles;
          userRoles.forEach((d1) => {
            for (let i = 0; i < allRoles.length; i++) {
              if (allRoles[i]._id == d1.role._id) {
                allRoles[i].isChecked = true;
              }
            }
          });
          this.rolesItems = allRoles;

          var b1 = this.rolesItems;
          var b2 = this.roles;
          var ress = b1.filter(
            (item1) =>
              !b2.some((item2) => {
                item2.role._id === item1._id;
              })
          );
          this.rolesItems = ress;
          if (this.rolesItems.length > 0) {
            this.extraRoles = false;
          }
        }
        this.loading = false;
      } else {
        let res = await this.getAllRoles();
        this.rolesItems = allRoles;
        this.extraRoles = true;
      }
    },
  },
};
</script>
